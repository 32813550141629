.customIconAndLink-IconUploader {
    overflow: auto;
    margin-top: 0.5rem;
}

.uploadBtn {
    float: left;
}

.widget {
    border: 1px solid rgba(0, 0, 0, 0.22);
    border-radius: 0.1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-top: 0.5rem;
}

.previewIcon {
    width: auto;
    height: 45px;
    margin-left: 0.5rem;
}

.cdx-quote-icon svg {
    transform: rotate(180deg);
}

.cdx-quote {
    margin: 0;
}

.cdx-quote__text {
    min-height: 158px;
    margin-top: 10px;
}

.customIconAndLink-text {
    margin-top: 10px;
    margin-bottom: 10px;
}

.cdx-url {
    margin-top: 10px;
}

.widget [contentEditable=true][data-placeholder]::before{
    position: absolute;
    content: attr(data-placeholder);
    color: #707684;
    font-weight: normal;
    opacity: 0;
}

.widget [contentEditable=true][data-placeholder]:empty::before {
    opacity: 1;
}

.widget [contentEditable=true][data-placeholder]:empty:focus::before {
    opacity: 0;
}

.cdx-dynamicContent__headline {
    text-transform: uppercase;
    margin-bottom: 0.5rem;
    display: block;
    color: lightgray;
}

.cdx-attachment {
    display: block;
    float: left;
    padding: 13px;
}